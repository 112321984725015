<template>
  <div v-if="items">
    <!-- slot top -->
    <div v-if="$slots.top">
      <slot name="top"></slot>
    </div>
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      show-select
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="id"
      class="m-2 font-weight-bold"
      hide-default-footer
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <!--    no-data end-->

      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 d-flex justify-space-between align-center flex-wrap"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>
          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('create')"
            >
              <button
                @click.prevent="showAddItemModal"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-plus-box-outline</v-icon>
                </span>
                Add {{ table_properties.title }}
              </button>
            </div>
            <add-item
              :loader="pageLoad"
              :refresher="updateTableContent"
              :stores="stores"
              ref="addItem"
            ></add-item>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="$refs.filterSidebar.$data.filterSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>
          </div>
        </div>
        <!-- Filter Sidebar start -->
        <FilterSidebar
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->
      </template>
      <template
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>

      <!-- top end -->
      <!-- column start -->
      <template #item.store_group="{ header, value }">
        <store-item :header="header" :store="value"></store-item>
      </template>

      <template v-slot:item.payments_label="{ value }">
        <text-column
          :value="item + ', '"
          v-for="(item, index) in value"
          :key="index"
        ></text-column>
      </template>

      <template #item.show_details="{ item }">
        <span>
          <v-btn color="primary" @click="showDetail(item)">Show Details</v-btn>
        </span>
      </template>

      <template #item.action="{ item, header, value }">
        <DataTableActionSelector
          :item="item"
          :header="header"
          :value="value"
          :actions="actions"
          :handle_function_call="handle_function_call"
        ></DataTableActionSelector>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <show-detail ref="showDetail"></show-detail>
    <edit-item
      ref="editItem"
      :loader="pageLoad"
      :refresher="updateTableContent"
    ></edit-item>
  </div>
</template>

<script>
import {
  UPDATE_TABLE_STRUCTURE,
  UPDATE_TABLE_DATA,
  UPDATE_CREATE_DATA,
  UPDATE_DETAIL_DATA,
  UPDATE_EDIT_DATA,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  SET_TABLE_FILTER,
  SET_TABLE_PERPAGE,
  SET_TABLE_PAGE,
} from "@/core/services/store/payments.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import StoreItem from "@/own/components/datatable/StoreItem.vue";

import AddItem from "./AddItem.vue";
import EditItem from "./EditItem.vue";
import ShowDetail from "./ShowDetail.vue";

import Pagination from "@/own/components/pagination/Pagination.vue";
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar";
import DataTableActionSelector from "@/own/components/DataTableActionSelector.vue";
import TextColumn from "@/own/components/datatable/TextColumn.vue";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";

export default {
  name: "DataTable",
  components: {
    DatatableDetail,
    Pagination,
    StoreItem,
    AddItem,
    EditItem,
    ShowDetail,
    FilterSidebar,
    DataTableActionSelector,
    TextColumn,
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    offset: true,
    allSelected: false,
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
    this.$store.dispatch(UPDATE_CREATE_DATA, {}).then(() => {});
    this.$store.dispatch(UPDATE_TABLE_DATA, {}).then(() => {});
  },
  beforeCreate() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
  computed: {
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    actions: function () {
      return [
        {
          title: "Edit",
          method: "editItem",
          isVisible: this.permissionChecker("update"),
        },
        {
          title: "Remove",
          method: "removeItem",
          isVisible: this.permissionChecker("destroy"),
        },
      ];
    },
    //pagination
    getitemPerPage: function () {
      return this.$store.getters.getPAYMENTSTableState.per_page;
    },
    getpageNumber: function () {
      return this.$store.getters.getPAYMENTSTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getPAYMENTSTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getPAYMENTSTableData.last_page;
    },
    //filters
    filters: function () {
      return this.$store.getters.getPAYMENTSTableFilters;
    },
    itemPerPage: function () {
      return this.$store.getters.getPAYMENTSTableState.per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getPAYMENTSTableState.sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getPAYMENTSTableState
          );
        } else {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getPAYMENTSTableState
          );
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (this.$store.getters.getPAYMENTSTableState.sortOrder === "asc") {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getPAYMENTSTableState
        );
      },
    },
    headers: function () {
      let headers = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.$store.getters.getPAYMENTSTableHeaders) {
          headers = this.$store.getters.getPAYMENTSTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getPAYMENTSTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        }
      } else {
        if (this.$store.getters.getPAYMENTSTableHeaders) {
          headers = this.$store.getters.getPAYMENTSTableHeaders.filter(
            (col) => col.visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getPAYMENTSTableHeaders.filter(
            (col) => col.visible === true
          );
        }
      }
      let supHeader = [...headers];
      const actions = supHeader.pop();
      supHeader.push({
        value: "show_details",
        text: "Show Details",
        type: "text",
        sortable: false,
        exportable: false,
        align: "left",
        itemClass: "second-text",
        width: "100px",
        class:
          "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls",
      });
      supHeader.push(actions);
      supHeader.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return supHeader;
    },
    items: function () {
      if (this.$store.getters.getPAYMENTSTableData) {
        return this.$store.getters.getPAYMENTSTableData.data;
      }
      return undefined;
    },
    table_permissions: function () {
      if (this.$store.getters.getPAYMENTSTablePermissions) {
        return this.$store.getters.getPAYMENTSTablePermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
    stores: function () {
      if (this.$store.getters.getPAYMENTSCreateData) {
        return this.$store.getters.getPAYMENTSCreateData.stores;
      }
      return undefined;
    },
    table_properties: function () {
      return this.$store.getters.getPAYMENTSTableProperties;
    },
  },
  methods: {
    // pagination
    setitemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setpageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = { ...this.$store.getters.getPAYMENTSTableState };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch((res) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          Swal.fire({
            title: "",
            text: `${res}`,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getCUSTOMTableState)
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    // filters end
    permissionChecker(permType) {
      if (this.table_permissions && this.table_permissions.includes(permType)) {
        return true;
      } else {
        return false;
      }
    },
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    async editItem(item) {
      await this.$store.dispatch(UPDATE_EDIT_DATA, {
        store_id: item.store_group.id,
      });
      this.$refs.editItem.toggleModal();
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          let config = {
            method: "post",
            url: `${process.env.VUE_APP_BASE_URL}/stores/portal/payments/destroy`,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            data: {
              store_id: item.store_group.id,
            },
          };
          axios(config)
            .then(() => {
              Swal.fire("Deleted!", "The Page has been deleted.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              Swal.fire("Error!", "Something went wrong.", "error");
              this.pageLoad(false);
            });
        }
      });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      this.pageLoad(true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getSTORESTableState)
        .then(() => {});
      this.$forceUpdate();
      this.pageLoad(false);
    },
    showAddItemModal() {
      this.$store.commit(SET_ITEM_FOR_ACTION, null);
      this.$refs.addItem.toggleModal();
    },
    async showDetail(item) {
      await this.$store.dispatch(UPDATE_DETAIL_DATA, {
        store_id: item.store_group.id,
      });
      this.$refs.showDetail.toggleModal();
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
  },
};
</script>
