<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Create Payment Gateways</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->

          <form id="createForm" class="pa-3 pb-0">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <template v-for="n in steps">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="e1 > n"
                    :step="n"
                  >
                    <span>{{ getStepperTitle(n) }}</span>
                  </v-stepper-step>

                  <v-divider v-if="n !== steps" :key="n"></v-divider>
                </template>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content :step="1" :key="`1-content`">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div>
                      <v-autocomplete
                        v-model="form.store_id"
                        :items="stores"
                        outlined
                        item-text="text"
                        item-value="index"
                        dense
                        clearables
                        label="Select Store*"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="form.gateways"
                        :items="payment_gateways"
                        outlined
                        item-text="name"
                        item-value="id"
                        dense
                        clearables
                        chips
                        small-chips
                        deletable-chips
                        label="Select Gateways"
                      ></v-autocomplete>
                    </div>

                    <!-- <span class="danger" v-if="$v.formData.gateways.$error">
                      Error Gateways</span
                    > -->
                    <div class="stepper-footer">
                      <div></div>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(1)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="2" :key="`2-content`">
                  <detail-gateway-form
                    v-if="this.form.gateways"
                    :data="getDetailData"
                    :country_selection_conditions="country_selection_conditions"
                    :is_submit="true"
                    :nextStep="nextStep"
                    :page="2"
                    type="add"
                    :previewStep="previewStep"
                    :storeGateway="storeGateway"
                  ></detail-gateway-form>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </form>
          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
import Swal from "sweetalert2";
import DetailGatewayForm from "./DetailGatewayForm";

export default {
  name: "AddItem",
  components: { DetailGatewayForm },
  props: ["loader", "refresher"],
  data: () => ({
    dialog: false,
    form: {
      store_id: null,
      gateways: null,
    },
    gateways: [],
    e1: 1,
    steps: 2,
  }),
  computed: {
    stores() {
      return this.$store.getters.getPAYMENTSCreateData.stores;
    },
    payment_gateways() {
      return this.$store.getters.getPAYMENTSCreateData.payment_gateways;
    },
    country_selection_conditions() {
      return this.$store.getters.getPAYMENTSCreateData
        .country_selection_conditions;
    },
    getDetailData() {
      if (this.form.gateways) {
        return this.payment_gateways.find(
          (item) => item.id == this.form.gateways
        );
      }
      return null;
    },
  },
  methods: {
    toggleModal() {
      this.resetData();
      this.dialog = !this.dialog;
    },
    closeModal() {
      this.dialog = false;
    },
    resetData() {
      this.form.store_id = null;
      this.form.gateways = null;
      this.gateways = [];
      this.e1 = 1;
    },
    getStepperTitle(n) {
      if (n == 1) return "Setting";
      else {
        return "Payment Setting";
      }
    },

    nextStep(page) {
      if (page == 1) {
        if (!this.form.store_id || !this.form.gateways) {
          this.errorMessage("Please select store and gateways!");
        } else this.e1 = page + 1;
      } else if (page == this.steps) {
        this.submit();
      } else {
        this.e1 = page + 1;
      }
    },
    previewStep(n) {
      if (n == 1) this.el = this.steps;
      else this.e1 = n - 1;
    },
    storeGateway(page, gateway) {
      // this.gateways.push(gateway);
      this.gateways[page - 2] = gateway;
    },
    errorMessage(message) {
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
    },
    submit() {
      this.loader(true);
      const updateUrl = `${process.env.VUE_APP_BASE_URL}/stores/portal/payments/store`;
      let config = {
        method: "post",
        url: `${updateUrl}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: {
          store_id: this.form.store_id,
          gateways: this.gateways,
        },
      };
      axios(config)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `New Payment has been Created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
          this.$forceUpdate();
        })
        .catch(() => {
          this.errorMessage("Something went wrong!");
          this.loader(false);
        });
    },
  },
};
</script>
