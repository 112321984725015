<template>
  <div class="justify-content-between stepper-container flex-column d-flex">
    <div>
      <v-autocomplete
        v-model="form.country_selection_condition"
        :items="country_selection_conditions"
        outlined
        item-text="title"
        item-value="id"
        dense
        clearables
        label="Select Country Condition"
      ></v-autocomplete>
      <v-autocomplete
        v-if="form.country_selection_condition != 1"
        v-model="form.countries"
        :items="data.countries"
        outlined
        item-text="title"
        item-value="id"
        dense
        clearables
        multiple
        chips
        small-chips
        deletable-chips
        label="Select Countries"
      ></v-autocomplete>
      <div v-if="data.credential_attributes">
        <v-card class="main mt-3" outlined>
          <v-card-title class="primary lighten-1 text-white">
            Credential for test
          </v-card-title>
          <v-card-text class="py-5">
            <v-text-field
              v-for="(item, key) in data.credential_attributes"
              :key="key"
              v-model="form.credentials_test[item]"
              :label="filteredString(item)"
              outlined
              required
            ></v-text-field>
          </v-card-text>
        </v-card>

        <v-card class="main my-3" outlined>
          <v-card-title class="primary lighten-1 text-white">
            Credential for Production
          </v-card-title>
          <v-card-text class="py-5">
            <v-text-field
              v-for="(item, key) in data.credential_attributes"
              :key="key"
              v-model="form.credentials_prod[item]"
              :label="filteredString(item)"
              outlined
              required
            ></v-text-field>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <div class="stepper-footer">
      <div>
        <button
          type="button"
          class="btn btn-light mr-3 px-5 py-3 ls1"
          @click="previewStep(page)"
        >
          Previous
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-info px-5 py-3 ls1"
          @click="next"
          v-if="is_submit"
        >
          Submit
        </button>
        <button
          type="button"
          class="btn btn-info px-5 py-3 ls1"
          @click="next"
          v-else
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailGatewayForm",
  props: [
    "data",
    "edit_data",
    "country_selection_conditions",
    "is_submit",
    "nextStep",
    "previewStep",
    "page",
    "type",
    "storeGateway",
  ],
  data: () => ({
    form: {
      id: null,
      country_selection_condition: null,
      countries: [],
      credentials_prod: null,
      credentials_test: null,
    },
  }),
  beforeMount() {
    this.resetData();
  },

  methods: {
    filteredString(str) {
      return str.replaceAll("_", " ");
    },
    resetData() {
      this.form.id = this.data.id;
      if (this.type == "add") {
        if (this.data.credential_attributes) {
          let attributes = {};
          this.data.credential_attributes.map(
            (item) => (attributes[item] = "")
          );
          this.form.credentials_test = { ...attributes };
          this.form.credentials_prod = { ...attributes };
        }
      } else {
        if (this.edit_data) {
          if (this.data.credential_attributes) {
            this.form.credentials_test = { ...this.edit_data.credentials_test };
            this.form.credentials_prod = { ...this.edit_data.credentials_prod };
          }
          this.form.country_selection_condition = 2;
          this.form.countries = this.edit_data.countries.map((item) => item.id);
        } else {
          if (this.data.credential_attributes) {
            let attributes = {};
            this.data.credential_attributes.map(
              (item) => (attributes[item] = "")
            );
            this.form.credentials_test = { ...attributes };
            this.form.credentials_prod = { ...attributes };
          }
        }
      }
    },
    next() {
      this.storeGateway(this.page, this.form);
      this.nextStep(this.page);
    },
  },
};
</script>
